<template>
  <div class="footer-bar-page mx-auto flex h-full max-w-[1920px] px-10 pad:px-5 mobile:px-5">
    <div class="flex w-full items-center justify-between gap-[40px] pad:basis-full mobile:basis-full">
      <div class="two-line-ellipsis flex-1 text-[16px] font-medium text-text-primary pad:hidden mobile:hidden" :title="detail.title">
        {{ detail.title }}
      </div>
      <div class="flex items-center gap-4 pad:basis-full pad:justify-between mobile:basis-full mobile:justify-between">
        <div class="not-pc:hidden">
          <div class="flex items-center justify-end gap-2 pad:justify-start mobile:justify-start">
            <span class="whitespace-nowrap text-level-2 font-bold text-text-primary">
              {{ payPrice }}
            </span>
            <span v-if="isDiscount" class="whitespace-nowrap text-[12px] text-[#999] line-through">
              {{ origPrice }}
            </span>
          </div>
        </div>
        <div class="flex gap-3 not-pc:w-full">
          <df-btn
            type="highlight"
            size="middle"
            class="buy-button flex w-full cursor-pointer items-center justify-center px-4 py-[6px] font-bold pad:!h-10 mobile:h-8"
            :loading="isBuyLoading"
            @click="onBuyClick"
          >
            <span class="mr-1 !hidden text-[20px] font-bold leading-[22px] mobile:!inline">{{ payPrice }}</span>
            <span class="whitespace-nowrap !text-level-3">Buy & Use</span>
          </df-btn>

          <df-btn type="special" size="middle" class="credit-buy not-pc:!hidden" @click="onCreditBuyClick">
            <span class="!text-level-3">Redeem</span>
            <span class="!text-level-8 !font-[400]">Credits {{ detail.credits || 0 }}</span>
          </df-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { priceComputed } from '@/components/project-details/common/price';
import { initStates } from '@/components/project-details/hook';
import { useDetailsStore } from '@/stores/details';
import { storeToRefs } from 'pinia';

const detailsStore = useDetailsStore();
const { handleBuyClick, handleCreditBuy, isBuyLoading, payConfirmModal } = initStates();

const { detail } = storeToRefs(detailsStore);
const isDiscount = computed(() => detail.value.hasDiscount);
const priceInfo = computed(() => priceComputed(detail.value));
const payPrice = computed(() => (isDiscount.value ? priceInfo.value.disCountPrice : priceInfo.value.origPrice));
const origPrice = computed(() => priceInfo.value.origPrice);

async function getData() {
  await detailsStore.getProjectDetailById(detail.value.id);
}
function onBuyClick() {
  handleBuyClick(detail, {
    position: '底部',
    isShowModal: true,
    isSubscription: true,
    refreshDetail: getData,
  });
}

function onCreditBuyClick() {
  handleCreditBuy('bottom');
}

onUnmounted(() => {
  if (payConfirmModal.value) {
    payConfirmModal.value.destroy();
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-btn.df-btn.credit-buy) {
  flex-direction: column;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
  background-color: transparent;
}
</style>
