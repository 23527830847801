<template>
  <div class="footer-bar-page mx-auto flex h-full max-w-[1920px] px-10 pad:px-5 mobile:px-5">
    <div class="flex w-full items-center justify-between gap-[40px] pad:basis-full mobile:basis-full">
      <div class="two-line-ellipsis flex-1 text-[16px] font-medium text-text-primary pad:hidden mobile:hidden" :title="detail.title">
        {{ detail.title }}
      </div>
      <div class="flex items-center gap-4 pad:basis-full pad:justify-between mobile:basis-full mobile:justify-between">
        <div>
          <div class="flex items-center justify-end gap-2 pad:justify-start mobile:justify-start">
            <span class="whitespace-nowrap text-level-2 font-bold text-[#E6A961]">
              {{ isDiscount ? priceInfo.disCountPrice : priceInfo.origPrice }}
            </span>
            <span v-if="isDiscount" class="whitespace-nowrap text-[12px] text-[#999] line-through">
              {{ priceInfo.origPrice }}
            </span>
          </div>
          <div
            v-if="isDiscount"
            :class="'inline-flex whitespace-nowrap rounded-[10px] bg-[#e6a961]/[.08] px-2 text-level-4 font-semibold text-[#e6a961]'"
          >
            You are saving {{ priceInfo.savePrice }}
          </div>
        </div>
        <div>
          <df-btn
            type="highlight"
            size="middle"
            class="buy-button flex w-full cursor-pointer items-center justify-center px-4 py-[6px] pc:!h-10 pad:h-8 mobile:h-8"
            :loading="isBuyLoading"
            @click="onBuyClick"
          >
            <icon-font class="h-6 text-[24px]" type="icon-shop-fill" />
            <span class="whitespace-nowrap text-level-3">Buy & Use</span>
          </df-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { priceComputed } from '@/components/project-details/common/price';
import { initStates } from '@/components/project-details/hook';
import { useDetailsStore } from '@/stores/details';
import { storeToRefs } from 'pinia';

const detailsStore = useDetailsStore();
const { handleBuyClick, isBuyLoading, payConfirmModal } = initStates();

const { detail } = storeToRefs(detailsStore);
const isDiscount = computed(() => detail.value.hasDiscount);
const priceInfo = computed(() => priceComputed(detail.value));

async function getData() {
  await detailsStore.getProjectDetailById(detail.value.id);
}
function onBuyClick() {
  handleBuyClick(detail, {
    position: '底部',
    isShowModal: true,
    isSubscription: true,
    refreshDetail: getData,
  });
}

onUnmounted(() => {
  if (payConfirmModal.value) {
    payConfirmModal.value.destroy();
  }
});
</script>

<style lang="less" scoped></style>
