<template>
  <Exposure v-if="items.length > 0" block-name="You may also like" class="bg-[#fff]">
    <div class="recommend m-auto max-w-[1920px] px-8 pb-10 pt-10 mobile:px-5">
      <h2 class="mb-0 ml-2 text-[20px] font-semibold text-text-primary">You may also like</h2>
      <div class="mx-auto mb-10 mt-[14px]">
        <BlockCardBox
          :list="items"
          is-show-user-card
          :opts="{
            algorithmVersion: 'v0',
            algorithmType: 'recommend_func_default',
            pageName: PageName.TEMPLATES,
            sceneName: SceneName.ATOMM,
            blockTitle: 'You may also like',
          }"
        />
        <BottomLoading
          class="mt-10"
          is-hide-bottom-line
          :loading="loadingState.isLoading"
          :finished="loadingState.isFinished"
          @infinite="handleInfiniteLoading"
        />
      </div>
    </div>
  </Exposure>
</template>

<script setup lang="ts">
import { PageName, SceneName } from '@/types/ga4';
import { getDesignRecommendListApi } from '~/api/project-details';

type PropsType = {
  id: number;
};
const props = defineProps<PropsType>();

const paging = reactive({
  current: 1,
  pageSize: 20,
  total: 0,
});
const loadingState = reactive({
  isLoading: false,
  isFinished: false,
});
const items = ref<any[]>([]);

const getData = async () => {
  const params = {
    current: paging.current,
    pageSize: paging.pageSize,
    mid: props.id,
  };
  try {
    loadingState.isLoading = true;
    const { data } = await getDesignRecommendListApi.fetch(params);
    const list = data?.list ?? [];
    const arr = handleDataDeduplication(unref(items), list);
    items.value.push(...arr);

    // 处理分页逻辑，判断是否加载完毕
    const { current, pageSize, total } = data?.pagination ?? {};
    paging.current = current;
    paging.total = total;
    loadingState.isFinished = current >= Math.ceil(total / pageSize);
  } catch (err) {
    console.log('err :>> ', err);
  } finally {
    loadingState.isLoading = false;
  }
};
getData();

// 下滑加载更多数据
const handleInfiniteLoading = async () => {
  if (loadingState.isLoading || loadingState.isFinished) {
    return;
  }
  paging.current++;
  await getData();
};
</script>

<style lang="less" scoped></style>
