<template>
  <div class="footer bg-newBg-subtlest pb-10 pt-10">
    <div class="m-auto max-w-[900px]">
      <h3 class="mb-0 text-center text-xl font-semibold text-text-primary pad:text-level-2 mobile:text-level-2">Do you like this {{ typeLabel }}?</h3>
      <div class="mt-6 flex justify-center gap-3">
        <a-tooltip placement="top" :title="isMyLike ? 'Unlike' : 'Like'">
          <ActionButton class="w-[120px]" shape="default" size="large" :active="isMyLike" :text="likeCount" @click="onActionTrigger('like')">
            <template #icon>
              <CommonLikeButton :is-liked="isMyLike" icon-type="nuxt-icon" :size="24" />
            </template>
          </ActionButton>
        </a-tooltip>
        <a-tooltip placement="top" :title="isMyFavorite ? 'Remove from your Bookmarks' : 'Add to Bookmarks'">
          <ActionButton
            class="w-[120px]"
            shape="default"
            size="large"
            :active="isMyFavorite"
            icon-type="nuxt-icon"
            icon="icon-bookmark-line2"
            active-icon="icon-a-bookmark-fill"
            :text="favoriteCount"
            @click="onActionTrigger('favorite')"
          />
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDetailsStore } from '@/stores/details';

const detailsStore = useDetailsStore();

const articleTypes: any = {
  xthings: 'xTool DIY Kit',
  designs: 'template',
  project: 'template',
  howtos: 'template',
  inspiration: 'template',
  bundle: 'bundle',
};

const detail = computed(() => detailsStore.detail);
const articleType = computed(() => detail.value.type ?? '');
const typeLabel = computed(() => articleTypes[articleType.value]);
const favoriteCount = computed(() => detail.value.actionSum?.favorite ?? 0);
const likeCount = computed(() => detail.value.actionSum?.like ?? 0);
const isMyLike = computed(() => detail.value.userAction?.like > 0);
const isMyFavorite = computed(() => detail.value.userAction?.favorite > 0);

const onActionTrigger = (type: string) => {
  detailsStore.handleToolStatusClick(type);
};
</script>

<style lang="less" scoped></style>
