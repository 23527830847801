<template>
  <div id="footer-tool-bar" class="sticky bottom-0 z-[60] bg-[#fff] shadow-[0_-4px_20px_0px_rgba(0,0,0,0.08)] pc:h-[60px] not-pc:py-3">
    <div class="footer-bar-page mx-auto flex h-full max-w-[1920px] px-10 pad:px-5 mobile:px-5">
      <div class="flex w-full items-center justify-between gap-[40px]">
        <div class="two-line-ellipsis flex-[1_0_0%] text-[16px] font-medium text-text-primary pad:hidden mobile:hidden" :title="projectTitle">
          {{ projectTitle }}
        </div>
        <div class="flex items-center gap-4 pad:w-full mobile:w-full">
          <div v-if="hasButtonGroup" class="button-wrap flex flex-nowrap justify-center gap-3 pad:flex-[1_0_0%] mobile:flex-[1_0_0%]">
            <!-- open in studio + Download 组合按钮 -->
            <div v-if="isOpenInStudioBtnShow && newAttachFiles.length > 0" class="flex-[1_0_100%] mobile:hidden">
              <df-dropdown-button
                type="highlight"
                size="middle"
                class="design-files-dropdown-footer"
                popover-class="design-files-dropdown-footer"
                placement="topRight"
                trigger-width="44px"
                :disabled="isPreview || isDownLoadAllLoading || isDownloadLoading"
                @click="onOpenInStudio"
              >
                <div v-if="isDownloadLoading || isDownLoadAllLoading" class="flex items-center justify-center gap-2.5">
                  <df-spin :stroke-color="'#fff'" :width="'20px'" />
                  <span class="text-level-3 font-semibold">Downloading</span>
                  <span class="text-level-3 font-semibold">{{ p }}%</span>
                </div>
                <div v-else class="flex items-center justify-center gap-2.5">
                  <icon-font class="h-6 text-[24px]" type="icon-XCS" />
                  <span class="text-level-2 font-semibold">Open in Studio</span>
                </div>
                <template #icon>
                  <NuxtIcon name="df:arrow-down-m" class="text-[20px]" />
                </template>
                <template #overlay>
                  <!-- w-[480px] trigger="click" -->
                  <div class="flex flex-col rounded-md border border-solid border-[#F0F0F5] bg-[#fff] p-1">
                    <div
                      class="flex cursor-pointer items-center gap-2 rounded bg-[#fff] px-2.5 py-2 hover:bg-[#f0f0f5]"
                      @click="onDownloadClick({ position: 'bottom' })"
                    >
                      <NuxtIcon name="df:icon-a-30-Download" class="text-[20px]" />
                      <span class="whitespace-nowrap text-text-primary">{{ downloadBtnTitle }}</span>
                    </div>
                  </div>
                </template>
              </df-dropdown-button>
            </div>

            <!-- Open in XCS -->
            <div v-if="isOpenBtnShow" class="flex-[1_0_0%] mobile:hidden">
              <df-btn
                class="flex w-full items-center justify-center !px-3"
                type="highlight"
                shape="square"
                size="large"
                :loading="isCallingXcs"
                :disabled="isPreview"
                @click="onOpenClick('bottom')"
              >
                <icon-font v-if="!isCallingXcs" class="h-6 text-[24px]" type="icon-XCS" />
                <span class="text-level-2 font-semibold">{{ xcsFileList.length > 1 ? 'Open All in XCS' : 'Open in XCS' }}</span>
              </df-btn>
            </div>

            <!-- 移动端要去掉open in studio,保留按钮形式的Download,没有studio文件时，下载按钮常驻-->
            <div
              class="hidden flex-[1_0_100%] mobile:block"
              :class="{
                '!block': !isOpenInStudioBtnShow,
                '!flex-[1_0_0%]': !isOpenInStudioBtnShow && isOpenBtnShow,
              }"
            >
              <df-btn
                :title="downloadBtnTitle"
                class="w-full items-center justify-center !px-3"
                :type="isOpenBtnShow ? 'primary' : 'highlight'"
                shape="square"
                :disabled="isPreview || isDownLoadAllLoading || isDownloadLoading"
                @click="onDownloadClick({ position: 'bottom' })"
              >
                <div v-if="isDownloadLoading || isDownLoadAllLoading" class="flex items-center gap-1">
                  <df-spin :stroke-color="'#fff'" :width="'20px'" />
                  <span class="text-level-2 font-semibold">Downloading</span>
                  <span>{{ p }}%</span>
                </div>
                <template v-else>
                  <NuxtIcon name="df:icon-a-30-Download" class="text-[20px]" />
                  <span class="ml-2 whitespace-nowrap text-level-2 font-semibold">{{ downloadBtnTitle }}</span>
                </template>
              </df-btn>
            </div>

            <!-- Import to Canvas 按钮 -->
            <div v-if="isImportToCanvasBtnShow" class="flex-[1_0_0%] mobile:hidden">
              <ClientOnly>
                <df-btn
                  class="flex w-full items-center justify-center !px-4"
                  type="default"
                  shape="square"
                  :loading="importToCanvasLoading"
                  :disabled="isPreview"
                  @click="onImportToCanvas"
                >
                  <icon-font v-if="!importToCanvasLoading" class="h-6 text-[24px]" type="icon-Export-line" />
                  <span class="text-level-2 font-semibold">Import to Canvas</span>
                </df-btn>
              </ClientOnly>
            </div>
          </div>
        </div>

        <!-- 文件弹窗 -->
        <ClientOnly>
          <FilesModal ref="filesModalRef" :opts="{ position: 'bottom' }" :files="studioFileList" />
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import FilesModal from '@/components/project-details/header/FilesModal.vue';
import { useFile } from '@/components/project-details/header/hooks/designFiles';

type PropsType = {
  isPreview?: boolean;
};
withDefaults(defineProps<PropsType>(), {
  isPreview: false,
});

const {
  newAttachFiles,
  downloadBtnTitle,
  isDownloadLoading,
  isDownLoadAllLoading,
  projectTitle,
  isOpenBtnShow,
  onOpenInStudio,
  filesModalRef,
  studioFileList,
  hasButtonGroup,
  isOpenInStudioBtnShow,
  onDownloadClick,
  p,
  isCallingXcs,
  onOpenClick,
  xcsFileList,
  isImportToCanvasBtnShow,
  importToCanvasLoading,
  onImportToCanvas,
} = useFile();
</script>

<style lang="less" scoped>
.button-wrap {
  .download-btn {
    transition: width 0.2s;
    :deep(.ant-btn) {
      .action {
        margin-left: 0;
      }
    }
  }
}
</style>
